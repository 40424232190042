import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import { useParams } from "react-router-dom";
import "./FileDashboard.css";
import { Card } from "react-bootstrap";
import DocumentNumber from "./DocumentNumber";
import { callApi } from "../../../utils/Api";

interface FileInformation {
    app_id: string;
    file_name: string;
    file_status: string;
    vtaken_status: string;
    taken_status: string;
    property_owner_name: string;
}

interface FileTime {
    login: string;
    scan_time: string;
    taken_date: string;
    Work_in_progress: string;
    Query_Raised: string;
    query_reiceved_date: string;
    typing_complited_dt: string;
    verification_completed_dt: string;
}

interface FlowGraphData {
    manualQuestion: any[];
}

interface DashboardProps {
    setLoading: (loading: boolean) => void;
}

const FileDashboard: React.FC<DashboardProps> = ({ setLoading }) => {
    const { id } = useParams();
    const [other, setOther] = useState({});
    const [ecdoc, setEcdoc] = useState({});
    const [revrecdoc, setRevrecdoc] = useState({});
    const [lodData, setLodData] = useState([]);
    const [queryData, setqueryData] = useState([]);
    const [flowGraphData, setFlowGraphData] = useState({
        manualQuestion: [],
        fileContent: "",
    });
    const [audioData, setAudioData] = useState([]);
    const [questionList, setQuestionList] = useState<any[]>([]);
    const [fileInfo, setFileInfo] = useState<FileInformation[]>([]);
    const [fileStatusSteps, setFileStatusSteps] = useState(0);
    const [fileTime, setFileTime] = useState<FileTime[]>([]);

    useEffect(() => {
        const storedUserDetails: any = sessionStorage.getItem("userDetails");
        const userDetails = JSON.parse(storedUserDetails);
        const userId = userDetails.userId;
        callApi("POST", "bank_url_doc_scrutinize_list.php", { id: id })
            .then((res) => res.data)
            .then((result) => {
                setLodData(result.data);
            })
            .catch((err) => {
                console.log(err);
            });
        callApi("POST", "bank_url_query_details.php", { fod_fk: id })
            .then((res) => res.data)
            .then((result) => {
                setqueryData(result.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(true);
        callApi("POST", "bank_url_file_dash.php", {
            branch_idfk: id,
            user_id: userId,
        })
            .then((res) => res.data)
            .then((response) => {
                setLoading(false);
                if (response.result === true) {
                    setOther(response?.data?.other);
                    setEcdoc(response?.data?.ec_doc);
                    setRevrecdoc(response?.data?.rev_rec_doc);
                    setFileInfo(response?.info);
                    setFileTime(response?.file_time);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        callApi("POST", "bank_url_file_status_steps.php", { id: id })
            .catch((res) => res.data)
            .then((response) => {
                if (response.data.step) {
                    setFileStatusSteps(response?.data?.step);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        callApi("POST", "bank_url_get_flowchart.php", {
            id: id,
        })
            .then((res) => res.data)
            .then((response) => {
                if (response.data && response.data) {
                    setFlowGraphData(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        callApi("POST", "bank_url_audio_get.php", {
            file_id: id,
        })
            .then((res) => res.data)
            .then((response) => {
                setAudioData(response.audioList);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        // console.log("flowGraphData____________", flowGraphData);
    }, [
        lodData,
        other,
        ecdoc,
        revrecdoc,
        queryData,
        fileInfo,
        fileStatusSteps,
        questionList,
        fileTime,
        audioData,
        flowGraphData,
    ]);

    useEffect(() => {
        // console.log("updated flowGraphData---", flowGraphData);
        let manualQuestionList: any = []; // Adjust the type as needed
        if (flowGraphData) {
            manualQuestionList = flowGraphData.manualQuestion;
        }
        if (manualQuestionList) {
            // const sanitizedManualQuestionList = manualQuestionList.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
            try {
                manualQuestionList = JSON.parse(manualQuestionList);
            } catch (error) {
                console.error("Error parsing manualQuestionList:", error);
                manualQuestionList=[];
            }
        }
        // console.log("Manual question list:", manualQuestionList);
        setQuestionList(manualQuestionList);
    }, [flowGraphData]);
    

    const accentStyle = "#5bc0de";
    const greenStyle = "#00861D";
    const grayStyle = "rgba(0, 0, 0, 0.5)";

    const loginStyle = {
        "background-color": ["-1"].includes(fileInfo[0]?.file_status)
            ? accentStyle
            : fileStatusSteps >= 1
                ? greenStyle
                : grayStyle,
    };
    const QueueStyle = {
        "background-color": ["0", "8"].includes(fileInfo[0]?.file_status)
            ? accentStyle
            : fileStatusSteps >= 2
                ? greenStyle
                : grayStyle,
    };
    const fileTakenStyle = {
        "background-color": ["2"].includes(fileInfo[0]?.file_status)
            ? accentStyle
            : fileStatusSteps >= 2
                ? greenStyle
                : grayStyle,
    };
    const wipStyle = {
        "background-color": ["2"].includes(fileInfo[0]?.file_status)
            ? accentStyle
            : fileStatusSteps >= 3
                ? greenStyle
                : grayStyle,
    };
    const QRStyle = {
        "background-color": ["7"].includes(fileInfo[0]?.file_status)
            ? accentStyle
            : fileStatusSteps >= 4
                ? greenStyle
                : grayStyle,
    };
    const QreceiveStyle = {
        "background-color": ["8"].includes(fileInfo[0]?.file_status)
            ? accentStyle
            : fileStatusSteps >= 5
                ? greenStyle
                : grayStyle,
    };
    const TCStyle = {
        "background-color":
            ["6"].includes(fileInfo[0]?.file_status) &&
                fileInfo[0]?.vtaken_status == "0" &&
                fileInfo[0]?.taken_status == "0"
                ? accentStyle
                : fileStatusSteps >= 6
                    ? greenStyle
                    : grayStyle,
    };
    const VcStyle = {
        "background-color":
            ["5"].includes(fileInfo[0]?.file_status) &&
                fileInfo[0]?.vtaken_status == "0" &&
                fileInfo[0]?.taken_status == "0"
                ? accentStyle
                : fileStatusSteps >= 7
                    ? greenStyle
                    : grayStyle,
    };
console.log("fileInfo[0]?.file_name ",fileInfo[0]?.file_name );

    return (
        <>
            <Header
                title={`APP ID:  ${fileInfo[0]?.app_id ? fileInfo[0]?.app_id : "N/A"}`}
                location="sub"
                path="filedash"
            />
            <div className="container mt-4 p-4">
                <div className="file-status-container">
                    <ul className="file-status-queue">
                        <li>
                            <div className="icon" style={loginStyle as any}>
                                1
                            </div>
                            <div className="title">Login</div>
                            <p className="date">
                                {fileTime.length > 0 && fileTime[0].login
                                    ? fileTime[0].login
                                    : ""}
                            </p>
                        </li>
                        <li>
                            <div className="icon" style={QueueStyle as any}>
                                2
                            </div>
                            <div className="title">Scan Complete</div>
                            <p className="date">
                                {fileTime.length > 0 && fileTime[0].scan_time
                                    ? fileTime[0].scan_time
                                    : ""}
                            </p>
                        </li>
                        {/* <li>
                            <div className="icon" style={fileTakenStyle as any}>
                                3
                            </div>
                            <div className="title">File Taken</div>
                            <p className="date">
                                {fileTime.length > 0 && fileTime[0].taken_date
                                    ? fileTime[0].taken_date
                                    : ""}
                            </p>
                        </li> */}
                        <li>
                            <div className="icon" style={wipStyle as any}>
                                3
                            </div>
                            <div className="title">Work in Progress</div>
                            <p className="date">
                                {fileTime.length > 0 && fileTime[0].Work_in_progress
                                    ? fileTime[0].Work_in_progress
                                    : ""}
                            </p>
                        </li>
                        <li>
                            <div className="icon" style={QRStyle as any}>
                                4
                            </div>
                            <div className="title">Query Raised</div>
                            <p className="date">
                                {fileTime.length > 0 && fileTime[0].Query_Raised
                                    ? fileTime[0].Query_Raised
                                    : ""}
                            </p>
                        </li>
                        <li>
                            <div className="icon" style={QreceiveStyle as any}>
                                5
                            </div>
                            <div className="title">Query Received</div>
                            <p className="date">
                                {fileTime.length > 0 && fileTime[0].query_reiceved_date
                                    ? fileTime[0].query_reiceved_date
                                    : ""}
                            </p>
                        </li>
                        <li>
                            <div className="icon" style={TCStyle as any}>
                                6
                            </div>
                            <div className="title">Typing Complete</div>
                            <p className="date">
                                {fileTime.length > 0 && fileTime[0].typing_complited_dt
                                    ? fileTime[0].typing_complited_dt
                                    : ""}
                            </p>
                        </li>
                        <li>
                            <div className="icon" style={VcStyle as any}>
                                7
                            </div>
                            <div className="title">Verification Complete</div>
                            <p className="date">
                                {fileTime.length > 0 && fileTime[0].verification_completed_dt
                                    ? fileTime[0].verification_completed_dt
                                    : ""}
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="row mt-5 document-number-container">
                    <DocumentNumber documentdetail={other} categoryname={"Document"} />
                    <DocumentNumber documentdetail={ecdoc} categoryname={"EC"} />
                    <DocumentNumber
                        documentdetail={revrecdoc}
                        categoryname={"Revenue Record"}
                    />
                </div>
                <div className="row mt-5">
                    <div
                        className="table-container sticky-table-container lod-table new-scroll"
                        style={{
                            height: lodData?.length > 5 ? "450px" : "auto",
                            padding: "0px",
                        }}
                    >
                        <table>
                            <thead className="sticky-table-header">
                                <tr className="head-row">
                                    <td className="table-space">S.No</td>
                                    <td className="table-space">Date</td>
                                    <td className="table-space">Doc.No</td>
                                    <td className="table-space">Particular of Deeds</td>
                                    <td className="table-space">Survey No</td>
                                    <td className="table-space">Nature of Document</td>
                                </tr>
                            </thead>
                            <tbody>
                                {lodData?.length > 0 ? (
                                    lodData.map((file: any, index: any) => {
                                        return (
                                            <tr className="body-row" key={index}>
                                                <td className="table-space" style={{paddingLeft:"35px"}}>{index + 1}</td>
                                                <td className="table-space">{file.date}</td>
                                                <td className="table-space">{file.doc_no}</td>
                                                <td className="table-space">{file.particulars}</td>
                                                <td className="table-space text-center">{file.old_surevey}</td>
                                                <td className="table-space text-center">{file.nature_doc}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <>
                                        <tr>
                                            <td
                                                colSpan={6}
                                                style={{
                                                    textAlign: "center",
                                                    justifyItems: "center",
                                                    color: "gray",
                                                    fontSize: "20px",
                                                    padding: "5%",
                                                }}
                                            >
                                                No List of Document Found.
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row mt-5 flowchart-container">
                    <div className="row chartrow">
                        <div className="col-md-6">
                            <h4>Flow Chart</h4>
                            <div className="chart-container">
                                {flowGraphData && flowGraphData?.fileContent != "" ? (
                                    <>
                                        <img
                                            src={flowGraphData.fileContent}
                                            alt="flow-chart"
                                            className="flowchart-img"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                textAlign: "left",
                                                width: "100%",
                                                fontSize: "14px",
                                            }}
                                        >
                                            No Flowchart Found.
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6 recording">
                            <h4>Voice Records</h4>
                            <div className="container">
                                <div
                                    className="row audio_div"
                                    style={{
                                        padding: "0px 20px",
                                        display: "block",
                                        marginTop: "5%",
                                    }}
                                >
                                    {audioData && audioData.length > 0 ? (
                                        audioData.map((audio: any, audioIndex: any) => {
                                            return (
                                                <div
                                                    key={audio.created_by + audioIndex}
                                                    className="mt-2"
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            color:
                                                                "var(--Color-Palette-Dark-Purpure, rgba(123, 87, 224, 1))",
                                                        }}
                                                        className="mt-2 mb-2"
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            {audioIndex + 1}.
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginLeft: "5px",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            }}
                                                        >
                                                            <div>{audio.created_by}</div>
                                                            <div
                                                                style={{
                                                                    marginLeft: "15px",
                                                                }}
                                                            >
                                                                {audio.created_dt}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "left",
                                                        }}
                                                    >
                                                        <audio controls src={audio.audio_base} />
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                width: "100%",
                                                fontSize: "14px",
                                            }}
                                        >
                                            No Voice Record Found.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 question-container">
                    <p className="title">Questions</p>
                    <div
                        className="row mt-2 new-scroll"
                        style={{
                            height: questionList?.length > 5 ? "350px !important" : "auto",
                        }}
                    >
                        {questionList?.length > 0 ? (
                            questionList.map((question: any, index: any) => (
                                <>
                                    <p key={index} className="q-para">
                                        {question.name}. {question.value}
                                    </p>
                                </>
                            ))
                        ) : (
                            <>
                                <div
                                    style={{
                                        textAlign: "left",
                                        width: "100%",
                                        fontSize: "14px",
                                    }}
                                >
                                    No Questions Found.
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="query-details mt-5">
                    <p className="title">Query Details</p>
                    <div className="row">
                        <div className="col-md-6 query-data">
                            <p>
                                File Name:{" "}
                                {fileInfo[0]?.property_owner_name
                                    ? fileInfo[0]?.property_owner_name
                                    : "N/A"}
                            </p>
                            <p>App ID: {fileInfo[0]?.app_id ? fileInfo[0]?.app_id : "N/A"}</p>
                            <p>
                                File No:{" "}
                                {fileInfo[0]?.file_name ? fileInfo[0]?.file_name : "N/A"}
                            </p>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div
                        className="query-table-data table-container sticky-table-container new-scroll"
                        style={{
                            height: queryData?.length > 5 ? "450px" : "auto",
                            padding: "0px",
                        }}
                    >
                        <table>
                            <thead className="sticky-table-header">
                                <tr className="head-row">
                                    <td className="table-space">S.No</td>
                                    <td className="table-space">Date</td>
                                    <td className="table-space">Query</td>
                                    <td className="table-space">Reason</td>
                                    <td className="table-space">Location</td>
                                    <td className="table-space">Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {queryData?.length > 0 ? (
                                    queryData.map((file: any, index: any) => {
                                        return (
                                            <tr className="body-row" key={index}>
                                                <td className="table-space" style={{paddingLeft:"35px"}}>{index + 1}</td>
                                                <td className="table-space">{file?.date}</td>
                                                <td className="table-space">{file.query}</td>
                                                <td className="table-space">{file.Reason}</td>
                                                <td className="table-space">{file?.location}</td>
                                                <td className="table-space">{file.query_status}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <>
                                        <tr>
                                            <td
                                                colSpan={6}
                                                style={{
                                                    textAlign: "center",
                                                    justifyItems: "center",
                                                    color: "gray",
                                                    fontSize: "20px",
                                                    padding: "5%",
                                                }}
                                            >
                                                No Query Details Found.
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FileDashboard;
