import React, { useState } from "react";
import ExcelJS from "exceljs";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import { callApi } from "../../../utils/Api";
import { Spinner } from "react-bootstrap";

const ExcelEodReport = ({ data }) => {
  const [loading, setLoading] = useState(false);

  const getCurrentFilesDetails = async () => {
    try {
      setLoading(true);
      const response = await callApi("POST", "bank_url_process_eod_new_excel.php", data);
      setLoading(false);
      if (response?.data?.data) {
        exportExcelFile(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const exportExcelFile = async (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid data format:", data);
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("EOD Report");

    worksheet.columns = [
      { header: "S.No", key: "s_no", width: 10 },
      { header: "LH Branch Name", key: "branch", width: 30 },
      { header: "LAN No", key: "app_id", width: 15 },
      { header: "LH File No", key: "file_name", width: 15 },
      { header: "Property Owner Name", key: "property_owner_name", width: 30 },
      { header: "Status", key: "file_status", width: 15 },
    ];

    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFFF00" } };
      cell.font = { bold: true, size: 14 };
    });

    data.forEach((file, index) => {
      worksheet.addRow({
        s_no: index + 1,
        branch: file.branch,
        app_id: file.app_id,
        file_name: file.file_name,
        property_owner_name: file.property_owner_name,
        file_status: file.file_status,
      }).eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      });
    });

    const dateStr = new Date().toLocaleDateString("en-GB").split("/").join("-");
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `Eod Report ${dateStr}.xlsx`;
    anchor.click();
    URL.revokeObjectURL(url);
  };

  return (
    <button className="download-btn" onClick={getCurrentFilesDetails} disabled={loading}>
      {loading ? <Spinner style={{ width: "20px", height: "20px" }} /> : <img className="download-image" src={excelDownloadIcon} alt="Download Excel" />} Download
    </button>
  );
};

export default ExcelEodReport;
