import React, { useState } from "react";
import ExcelJS from "exceljs";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import { callApi } from "../../../utils/Api";
import { Spinner } from "react-bootstrap";

const ExcelPendingQuery = ({ data }) => {
  const [loading, setLoading] = useState(false);

  const getCurrentFilesDetails = async () => {
    try {
      setLoading(true);
      const response = await callApi(
        "POST",
        "bank_url_pending_query_file_new_excel.php",
        data
      );
      setLoading(false);
      if (response?.data?.data) {
        exportExcelFile(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const exportExcelFile = async (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid data format:", data);
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Pending Queries");

    worksheet.columns = [
      { header: "S.No", key: "s_no", width: 10 },
      { header: "Name", key: "property_owner_name", width: 30 },
      { header: "LH File No", key: "file_name", width: 15 },
      { header: "Application No", key: "application_no", width: 30 },
      { header: "Queries", key: "query", width: 50 },
      { header: "Query Raised Date & Time", key: "query_raised_dt", width: 30 },
    ];

    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
      };
      cell.font = { bold: true, size: 14 };
    });

    data.forEach((file, index) => {
      worksheet
        .addRow({
          s_no: index + 1,
          property_owner_name: file.property_owner_name,
          file_name: file.file_name,
          application_no: file.application_no,
          query: file.query.map((q, i) => `${i + 1}. ${q.query}`).join("\n"),
          query_raised_dt: file.query_raised_dt,
        })
        .eachCell((cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
    });

    const dateStr = new Date().toLocaleDateString("en-GB").split("/").join("-");
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `Pending Query ${dateStr}.xlsx`;
    anchor.click();
    URL.revokeObjectURL(url);
  };

  return (
    <button
      className="download-btn"
      onClick={getCurrentFilesDetails}
      disabled={loading}
    >
      {loading ? (
        <Spinner style={{ width: "20px", height: "20px" }} />
      ) : (
        <img
          className="download-image"
          src={excelDownloadIcon}
          alt="Download Excel"
        />
      )}{" "}
      Download
    </button>
  );
};

export default ExcelPendingQuery;
