import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Dashboard/Dashboard.css";
import calendarIcon from "../../../assets/dashboard/calendar-icon.png";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import pageleft from "../../../assets/dashboard/pagination-left.png";
import pageright from "../../../assets/dashboard/pagination-right.png";
import Header from "../../Layout/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import "select2";
import "select2/dist/js/select2";
import "select2/dist/css/select2.min.css";
import { isEmpty } from "lodash";
import { callApi } from "../../../utils/Api";
import { showError } from "../../../utils/sweetAlert";
import Select from "react-select";
import * as XLSX from 'xlsx';
import ExcelEodReport from '../Reports/ExcelEodReport';

interface DashboardProps {
  setLoading: (loading: boolean) => void;
}

const EodReport: React.FC<DashboardProps> = ({ setLoading }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [toDate, setToDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [appid, setAppid] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [showToDateCalendar, setShowToDateCalendar] = useState(false);
  const [showFromDateCalendar, setShowFromDateCalendar] = useState(false);
  const toDateRef = useRef<HTMLDivElement | null>(null);
  const fromDateRef = useRef<HTMLDivElement | null>(null);
  const [bankList, setBankList] = useState([]);
  const [filteredBankList, setFilteredBankList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const selectRef = useRef<HTMLSelectElement>(null);
  const selectBranchRef = useRef<HTMLSelectElement>(null);
  let select2Instance: any = null;
  const [bankBranchList, setBankBranchList] = useState([]);
  const [filterBankBranchList, setFilterBankBranchList] = useState([]);
  const [selectedBranchItems, setSelectedBranchItems] = useState([]);
  const [filesDetails, setFilesDetails] = useState<
    { total: any; status: any }[]
  >([]);
  const [currentfilesDetails, setCurrentFilesDetails] = useState<
    { total: any; status: any }[]
  >([]);
  const [isNotValid, setIsNotValid] = useState(false);
  const [selectBankId, setSelectBankId] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [filterBankProductList, setFilterBankProductList] = useState([]);
  const [selectedProduct, setselectedProduct] = useState("");
  const [bankProductList, setBankProductList] = useState([]);
  const [filterBranchList, setFilterBranchList] = useState([]);
  const [limitList, setLimitList] = useState(['5', '10', '15', '20', 'All']);
  const [selectLimit, setSelectLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedlimit, setSelectedlimit] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalFileCount, setTotalFileCount] = useState(0);

  const storedUserDetails: any = sessionStorage.getItem("userDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const userId = userDetails.userId;
  const selectedBanksIds = userDetails.bank_id;
  const selectedBranchsIds = userDetails.bank_branch_id;
  const selectedProductId = userDetails.selected_product_id;
  const user_type = userDetails.user_type;

  useEffect(() => {
    getBankBranches();
    getBanks();
    getBankproduct();
    getCurrentFilesDetails(1);
  }, []);

  const getBankproduct = () => {
    callApi("GET", "bank_product_get.php")
      .then((res) => res.data)
      .then((result) => {
        const data = result.data;
        if (user_type == "0") {
          setBankProductList(data);
        } else {
          const filteredproduct = data.filter((bank: any) =>
            selectedProductId.includes(bank.id)
          );
          setBankProductList(filteredproduct);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBanks = () => {
    callApi("GET", "bank_get.php")
      .then((res: any) => res.data)
      .then(async (result: any) => {
        const data = result.data;
        if (user_type == "0") {
          setBankList(data);
        } else {
          const filteredBanks = data.filter((bank: any) =>
            selectedBanksIds.includes(bank.id)
          );
          setBankList(filteredBanks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankBranches = () => {
    callApi("GET", "bank_branch_get.php")
      .then((res) => res.data)
      .then((result) => {
        const data = result.data;
        if (user_type == "0") {
          setBankBranchList(data);
        } else {
          const filteredBanks = data.filter((bank: any) =>
            selectedBranchsIds.includes(bank.id)
          );
          setBankBranchList(filteredBanks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("selectBankId____", selectBankId, selectedBranch);
  }, [
    filteredBankList,
    selectBankId,
    bankList,
    bankBranchList,
    branchList,
    selectedBranch,
  ]);

  const handleAppId = (event: any) => {
    setAppid(event.target.value);
  };

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const parseFormattedDate = (formattedDate: string): Date => {
    const parts = formattedDate.split("-");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1;
    const year = parseInt(parts[2]);
    return new Date(year, month, day);
  };

  const handleFromDateChange = (date: Date | null) => {
    if (date) {
      const selectedFromDate = date;
      const formattedDate = formatDate(selectedFromDate);
      if (!toDate || parseFormattedDate(fromDate) >= selectedFromDate) {
        setFromDate(formattedDate);
        setShowFromDateCalendar(false);
      } else {
        showError(
          "Failed!",
          "Selected StartDate must be less than or equal to EndDate"
        );
        console.error("Selected fromDate must be less than or equal to toDate");
      }
    }
  };

  const handleToDateChange = (date: Date | null) => {
    if (date) {
      const selectedToDate = date;
      const formattedDate = formatDate(selectedToDate); // Format date function
      if (!fromDate || parseFormattedDate(fromDate) <= selectedToDate) {
        setToDate(formattedDate);
        setShowToDateCalendar(false);
      } else {
        showError(
          "Failed!",
          "Selected EndDate must be greater than or equal to StartDate"
        );
        console.error(
          "Selected toDate must be greater than or equal to fromDate"
        );
      }
    }
  };

  const handleBankChange = (e: any, type: any) => {
    const { name, id } = e;
    let selectedBankIds: any = [];
    selectedBankIds.push(id);
    filterProduct(id);
    filterBranches(id);
    if (id == "all" && user_type == "1") {
      setSelectBankId(selectedBanksIds);
    } else if (id == "all" && user_type == "0") {
      setSelectBankId([]);
      setSelectedBranchItems([]);
      setFilterBranchList([]);
    } else {
      setSelectBankId(selectedBankIds);
    }
    if (user_type == "0") {
      const filteredBranches = bankBranchList.filter(
        (branch: any) => branch.bank_id === id
      );
      setFilterBankBranchList(filteredBranches);
    }
  };

  const filterProduct = (bankid: any) => {
    if (bankid) {
      const filteredProduct = bankProductList.filter(
        (branch: any) => branch.bank_id === bankid
      );
      setFilterBankProductList(filteredProduct);
    } else {
      setselectedProduct("");
    }
  };

  const handleProductChange = (e: any, type: any) => {
    const { product_id, id } = e;
    if (product_id != "all") {
      setselectedProduct(id);
      filterBranchesproduct(id);
    } else {
      setselectedProduct("");
    }
  };
  const filterBranches = (bankid: any) => {
    const filteredBranches = bankBranchList.filter(
      (branch: any) => branch.bank_id === bankid
    );
    setFilterBankBranchList(filteredBranches);
  };
  const filterBranchesproduct = (productid: any) => {
    let bankids: any = selectBankId;
    let bankidArray = bankids[0];
    let firstbankId = bankidArray;
    const filteredBranches = filterBankBranchList.filter((branch: any) => {
      return branch.product_id === productid && branch.bank_id === firstbankId;
    });
    setFilterBranchList(filteredBranches);
  };

  const handleBranchChange = (e: any) => {
    const { name, id } = e;
    let selectedBranchIds: any = [];
    selectedBranchIds.push(id);
    if (id == "all" && user_type == "1") {
      setSelectedBranchItems(selectedBranchsIds);
    } else if (id == "all" && user_type == "0") {
      setSelectedBranchItems([]);
    } else {
      setSelectedBranchItems(selectedBranchIds);
    }
  };

  const searchBranchStatus = () => {
    // if (isEmpty(fromDate) || isEmpty(toDate)) {
    //     setIsNotValid(true);
    // } else {
    //     setIsNotValid(false);
    //     getFilesDetails();
    // }
    getFilesDetails();
  };

  const getFilesDetails = () => {
    const storedUserDetails: any = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(storedUserDetails);
    const userId = userDetails.userId;
    const request = {
      from_date: fromDate,
      to_date: toDate,
      bankname: selectBankId.length > 0 ? selectBankId : selectedBanksIds,
      bankbranch:
        selectedBranchItems.length > 0
          ? selectedBranchItems
          : selectedBranchsIds,
      userId: userId,
      app_id: appid,
      product_id: selectedProduct,
      page_id: pageCount,
      row_count: "20"
    };
    setLoading(true);
    // callApi("POST", "bank_url_process_eod_new.php", request)
    //   .then((res) => res.data)
    //   .then((result) => {
    //       setFilesDetails(result.data);
    //     setTotalFileCount(result.count);
    //     setCurrentPage(prevPage => {
    //       const newPage = prevPage;
    //       return newPage;
    //     });

    //     setLoading(false);
    //     const data = result;
    //     data.from_date = fromDate;
    //     data.to_date = toDate;
    navigate("/layout/eod-sub-report", { state: { request } });
    //     setFilesDetails(result.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setLoading(false)
  };

  const getCurrentFilesDetails = (pagecount: any) => {
    setCurrentFilesDetails([]);
    const storedUserDetails: any = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(storedUserDetails);
    const userId = userDetails.userId;
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding padding if month is single digit
    const day = date.getDate().toString().padStart(2, "0"); // Adding padding if day is single digit
    const formattedDate = `${day}-${month}-${year}`;
    const eodreportlocalgetitem: any = localStorage.getItem("eodreportlocalstorage");
    if (eodreportlocalgetitem !== null && eodreportlocalgetitem !== "" && eodreportlocalgetitem !== undefined) {
      setCurrentFilesDetails(JSON.parse(eodreportlocalgetitem))
    } else {
      const request = {
        from_date: formattedDate,
        to_date: formattedDate,
        bankname: selectBankId.length > 0 ? selectBankId : selectedBanksIds,
        bankbranch:
          selectedBranchItems.length > 0
            ? selectedBranchItems
            : selectedBranchsIds,
        userId: userId,
        app_id: appid,
        product_id: selectedProduct,
        page_id: pagecount,
        row_count: "20",
      };
      setLoading(true);
      callApi("POST", "bank_url_process_eod_new.php", request)
        .then((res) => res.data)
        .then((result) => {
          setCurrentFilesDetails(result.data);
          // localStorage.setItem("eodreportlocalstorage", JSON.stringify(result.data))
          // const eodsettime: any = Date.now()
          // localStorage.setItem("eodloadtime", eodsettime)
          setFilesDetails(result.data);
          setTotalFileCount(result.count);
          setCurrentPage(prevPage => {
            const newPage = prevPage;
            return newPage;
          });

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const downloadExcel = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
    const flattenedData = currentfilesDetails.map((file: any, index: any) => {
      const branch = file.branch;
      return {
        "S.No": file.s_no,
        "File Name": file.file_name,
        "Lan No": file.app_id,
        "Property Owner Name": file.property_owner_name,
        "LH Branch Name": branch,
        "Status": file.file_status
      };
    });
    const headers = [
      'S.No',
      'File Name',
      'Lan No',
      'Property Owner Name',
      'LH Branch Name',
      'Status'
    ];
    const worksheet = XLSX.utils.json_to_sheet(flattenedData, { header: headers });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, 'Files');
    const fileName = `Eod Report ${formattedDate}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  // const pageCount = Math.ceil(currentfilesDetails.length / itemsPerPage);

  const handlePageClickprevious = () => {
    setCurrentPage(prevPage => {
      const newPage = prevPage - 1;
      getCurrentFilesDetails(newPage);

      return newPage;
    });
  };
  const handlePageClicknext = () => {
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;
      getCurrentFilesDetails(newPage);

      return newPage;
    });
  };

  const handlepageListChange = (event: any) => {
    if (event.target.value == "All") {
      setSelectedlimit(event.target.value);
      setSelectLimit(event.target.value);
      setItemsPerPage(totalFileCount);

    } else {
      setSelectLimit(event.target.value);
      setItemsPerPage(event.target.value);
    }
  };
  const pageCount = Math.ceil(totalFileCount / itemsPerPage);

  const indexOfLastItem = (currentPage) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = currentfilesDetails;


  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        toDateRef.current &&
        !(toDateRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setShowToDateCalendar(false);
      }
      if (
        fromDateRef.current &&
        !(fromDateRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setShowFromDateCalendar(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const routeToDashboard = (id: any) => {
    navigate(`/layout/filedash/${id}`);
  }

  const banksoptions = [{ id: "all", name: "All" }, ...bankList];

  const filteredBranchesoptions = [
    { id: "all", name: "All" },
    ...filterBranchList,
  ];

  const filteredProduct = [
    { product_id: "all", product_name: "All" },
    ...filterBankProductList,
  ];

  let bankids: any = selectBankId;
  let bankidArray = bankids[0];
  let firstbankId = bankidArray;

  let branchids: any = selectBankId;
  let branchidArray = branchids[0];
  let firstbranchId = branchidArray;

  const request = {
    from_date: formattedDate,
    to_date: formattedDate,
    bankname: selectBankId.length > 0 ? selectBankId : selectedBanksIds,
    bankbranch:
      selectedBranchItems.length > 0
        ? selectedBranchItems
        : selectedBranchsIds,
    userId: userId,
    app_id: appid,
    product_id: selectedProduct,
  };
  const today = new Date();
  const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const threeMonthsAgoStart  = new Date(today.getFullYear(), today.getMonth() - 3, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  return (
    <>
      <Header title="EOD Report" location="main" path="report" />
      <div className="container mobile-view-dash-filter">
        <div className="card">
          <div className="card-content ">
            <div
              className="row mt-2 mobile-view-search"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className="col-md-2">
                <label>Start Date</label>
                <div className="form-field" ref={fromDateRef}>
                  <input
                    type="text"
                    id="fromdate"
                    placeholder="Select Date"
                    value={fromDate}
                    readOnly
                    onClick={() =>
                      setShowFromDateCalendar(!showFromDateCalendar)
                    }
                  />
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    onClick={() =>
                      setShowFromDateCalendar(!showFromDateCalendar)
                    }
                  />
                  {showFromDateCalendar && (
                    <DatePicker
                      onChange={handleFromDateChange}
                      inline
                      calendarClassName="white-background"
                      popperPlacement="bottom-start"
                      maxDate={today} // Current month's present date
                      minDate={threeMonthsAgoStart} // First day of 3 months ago
                      filterDate={(date) => {
                        // Enable dates from the last 3 months and today's date in the current month
                        const isInLastThreeMonths = date >= threeMonthsAgoStart && date <= lastMonthEnd;
                        const isInCurrentMonthToToday = date >= currentMonthStart && date <= today;

                        return isInLastThreeMonths || isInCurrentMonthToToday;
                      }}
                      showDisabledMonthNavigation
                    />
                  )}
                </div>
                {isNotValid && isEmpty(fromDate) ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-2">
                <label>End Date</label>
                <div className="form-field" ref={toDateRef}>
                  <input
                    type="text"
                    id="todate"
                    placeholder="Select Date"
                    value={toDate}
                    readOnly
                    onClick={() => setShowToDateCalendar(!showToDateCalendar)}
                  />
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    onClick={() => setShowToDateCalendar(!showToDateCalendar)}
                  />
                  {showToDateCalendar && (
                    <DatePicker
                      onChange={handleToDateChange}
                      inline
                      calendarClassName="white-background"
                      popperPlacement="bottom-start"
                      maxDate={today} // Current month's present date
                      minDate={threeMonthsAgoStart} // First day of 3 months ago
                      filterDate={(date) => {
                        // Enable dates from the last 3 months and today's date in the current month
                        const isInLastThreeMonths = date >= threeMonthsAgoStart && date <= lastMonthEnd;
                        const isInCurrentMonthToToday = date >= currentMonthStart && date <= today;

                        return isInLastThreeMonths || isInCurrentMonthToToday;
                      }}
                      showDisabledMonthNavigation
                    />
                  )}
                </div>
                {isNotValid && isEmpty(toDate) ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-2">
                <label>Select Bank</label>
                <div className="select-field">
                  {banksoptions &&
                    banksoptions.length > 0 &&
                    banksoptions.map((opt: any) => {
                      opt.label = opt.name;
                      opt.value = opt.id;
                    }) && (
                      <Select
                        options={banksoptions}
                        name="bank_id"
                        value={banksoptions.filter(
                          (option: any) => option.id === firstbankId
                        )}
                        onChange={(e: any) => handleBankChange(e, "bank")}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2">
                <label>Select Product</label>
                <div className="select-field">
                  {filteredProduct &&
                    filteredProduct.map((opt: any) => {
                      opt.label = opt.product_name;
                      opt.value = opt.product_id;
                    }) && (
                      <Select
                        options={filteredProduct}
                        name="product_id"
                        value={filteredProduct.find(
                          (o: any) => o.id === selectedProduct
                        )}
                        onChange={(e) => handleProductChange(e, "product_id")}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2">
                <label>Select Branch</label>
                <div className="select-field">
                  {filteredBranchesoptions &&
                    filteredBranchesoptions.length > 0 &&
                    filteredBranchesoptions.map((opt: any) => {
                      opt.label = opt.name;
                      opt.value = opt.id;
                    }) && (
                      <Select
                        options={filteredBranchesoptions}
                        name="branch"
                        value={filteredBranchesoptions.find(
                          (o: any) => o.value == firstbranchId
                        )}
                        onChange={(e) => handleBranchChange(e)}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2">
                <label>APP ID / LH File No</label>
                <div className="form-field">
                  <input
                    type="text"
                    id="appid"
                    placeholder="APP ID / LH File No"
                    onChange={(e) => handleAppId(e)}
                    value={appid}
                  />
                </div>
              </div>
              <div className="col-md-2 formsubmit">
                <button
                  className="sumbit-btn"
                  onClick={() => searchBranchStatus()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mobile-view-file-table-eod-report">
          <div className="card card-zindex">
            <div className="row table-main-header">
              <div className="col-md-6 pl-3">
                {/* <p className="branch-name">Branch Name</p> */}
                <p className="filtered-date">
                  {/* {formattedDate} To {formattedDate} */}
                  Today Details
                </p>
              </div>
              <div className="col-md-6 pr-3">
                {/* <button className="download-btn" onClick={downloadExcel}>
                  <img
                    className="download-image"
                    src={excelDownloadIcon}
                    alt=""
                  />
                  Download
                </button> */}
                <ExcelEodReport data={request} />
              </div>
            </div>
            <div className="table-container report-table pl-3 pr-3">
              <table>
                <thead>
                  <tr className="head-row">
                    <td>S.No</td>
                    <td>LH Branch Name</td>
                    <td>LAN No</td>
                    <td>LH File No</td>
                    <td>Property Owner Name</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.length > 0 &&
                    currentItems.map((file: any, index: any) => {
                      return (
                        <tr className={`body-row-eod ${file.file_status == "under process" ? "orange-row" : (file.file_status == "Queries not cleared") ? "red-row" : (file.file_status == "Report Released") ? "green-row" : ""}`} key={index} onClick={() => routeToDashboard(file.id)}>
                          <td>{file.s_no}</td>
                          <td>{file.branch}</td>
                          <td>{file.app_id}</td>
                          <td>{file.file_name}</td>
                          <td>{file.property_owner_name}</td>
                          <td style={{ width: "25%" }}>{file.file_status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="pagination-and-info">
                {/* Pagination */}
                <div className="pagination">
                  <button onClick={handlePageClickprevious} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === 1}>
                    <img
                      src={pageleft}
                      alt="left arrow"
                      style={{ marginRight: "5px", width: "7px", height: "12px" }}
                    />
                  </button>
                  <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px", backgroundColor: "white", margin: "5px 0px 0px 5px", display: 'flex' }}>
                    {pageCount === 0 ? "0" : currentPage}
                    <p style={{ padding: "0px 10px 0px 10px" }}>/</p>
                    {pageCount}
                  </span>
                  <button onClick={handlePageClicknext} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === pageCount}>
                    <img
                      src={pageright}
                      alt="right arrow"
                      style={{ marginRight: "5px", width: "7px", height: "12px" }}
                    />
                  </button>
                </div>
                <div className='pagination'>
                  <span style={{ marginRight: "10px", marginTop: "15px" }}>Total Count : {totalFileCount}</span>

                </div>
                {/* <div className="row-per-page mt-5">
                  <span style={{ marginRight: "10px" }}>Rows per page</span>
                  <select className='select' aria-placeholder='Choose Branch' value={selectLimit} onChange={handlepageListChange} style={{ height: "30px", width: "48px", padding: "0px 2px 0px" }}>
                    {limitList.map((limitValue, index) => (
                      <option key={index} value={limitValue}>
                        {limitValue}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EodReport;
