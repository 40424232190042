import React, { useEffect, useState, useRef } from "react";
import "./Adduser.css";
import ReactPaginate from "react-paginate";
import showPassIcon from "../../../assets/login/eye-off.png";
import hidePassIcon from "../../../assets/login/eye-on.png";
import pageleft from "../../../assets/dashboard/pagination-left.png";
import pageright from "../../../assets/dashboard/pagination-right.png";
import editIcon from "../../../assets/dashboard/edit_icon.png";
import deleteIcon from "../../../assets/dashboard/delete_icon.png";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { Modal, Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { conforms, isEmpty, stubFalse } from "lodash";
import { useNavigate, useRevalidator } from "react-router-dom";
import { showAlert, showError, showSuccess } from "../../../utils/sweetAlert";
import SuccessModal from "../../../utils/SuccessModal";
import { callApi } from "../../../utils/Api";
import TopNavBar from "../../Layout/TopNavBar";
import $ from "jquery";
import "select2";
import "select2/dist/js/select2";
import "select2/dist/css/select2.min.css";
import * as XLSX from "xlsx";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import { MultiSelect } from "react-multi-select-component";
import ExcelUser from "./ExcelUser";
interface Branch {
  bank_id: string;
}

interface UserData {
  id: string;
  name: string;
  email: string;
  phone_no: string;
  password: string;
  confirm_password: string;
  bank_id: string[]; // Assuming it's an array of strings
  bank_branch_id: string[]; // Assuming it's an array of strings
  // Add other properties if necessary
}

const AddUser = () => {
  const navigate = useNavigate();
  const [userlist, userList] = useState([]);
  const [limitList, setLimitList] = useState(["5", "10", "15", "20", "All"]);
  const [selectLimit, setSelectLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedlimit, setSelectedlimit] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [show, setShow] = useState(false);
  const [isValid, setIsvalid] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedBankId, setSelectedBankId] = useState<string[]>([]);
  const [selectedBranchIds, setSelectedBranchIds] = useState<string[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<string[]>([]);
  const [selectedIdProduct, setSelectedIdProduct] = useState<string[]>([]);
  const [Nameerror, setNameerror] = useState(false);
  const [Emailerror, setEmailerror] = useState(false);
  const [Mobilenoerror, setMobilenoerror] = useState(false);
  const [passworderror, setpassworderror] = useState(false);
  const [confirmPassword, setConfirmpassword] = useState(false);
  const [PasswordMatch, setPasswordMatch] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [ShowpassWord, setShowpassWord] = useState(false);
  const [ShowPassWord, setShowPassWord] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formFields, setFormfields] = useState({
    name: "",
    id: "",
    email: "",
    mob_no: "",
    password: "",
    confirm_password: "",
    selectedBankId: [""],
    selectedBranchId: [""],
    selectedProductId: [""],
  });
  const [bankList, setBankList] = useState([]);
  const [bankBranchList, setBankBranchList] = useState([]);
  const [filterBankBranchList, setFilterBankBranchList] = useState([]);
  const selectbankRef = useRef<HTMLSelectElement>(null);
  const selectproductRef = useRef<HTMLSelectElement>(null);
  let select2Instance: any = null;
  const selectBranchRef = useRef<HTMLSelectElement>(null);
  const [selectedBranchItems, setSelectedBranchItems] = useState<string[]>([]);
  const [selectedBankItems, setSelectedBankItems] = useState<string[]>([]);
  const [selectedProduct, setselectedProduct] = useState("");
  const [bankProductList, setBankProductList] = useState([]);
  const [filterBankProductList, setFilterBankProductList] = useState([]);
  const [filterBranchList, setFilterBranchList] = useState([]);

  const [state, setState] = useState({
    UserListSet: [],
  });

  useEffect(() => {
    getBankBranches();
    getBanks();
    getBankproduct();
    getUserDetails();
  }, []);

  useEffect(() => { }, [
    userlist,
    selectedBankId,
    selectedBranchIds,
    formFields,
    formFields,
    state.UserListSet,
    bankList,
    bankBranchList,
    selectedBankItems,
    selectedProductId,
    bankProductList,
    filterBankBranchList,
    filterBankProductList,
  ]);

  useEffect(() => {
    console.log(
      "selectedProductId____",
      selectedProductId,
      selectedBankId,
      selectedBranchIds
    );
  }, [selectedProductId, selectedBankId, selectedBranchIds]);

  const storedUserDetails: any = sessionStorage.getItem("userDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const selectedBanksIds = userDetails.bank_id;
  const selectedBranchsIds = userDetails.bank_branch_id;
  const user_type = userDetails.user_type;
  const pageCount = Math.ceil(state.UserListSet.length / itemsPerPage);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = state.UserListSet.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const togglePasswordVisibility = () => {
    setShowpassWord(!ShowpassWord);
  };

  const togglepasswordVisibility = () => {
    setShowPassWord(!ShowPassWord);
  };

  const getBanks = () => {
    callApi("GET", "bank_get.php")
      .then((res: any) => res.data)
      .then(async (result: any) => {
        setBankList(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankproduct = () => {
    callApi("GET", "bank_product_get.php")
      .then((res) => res.data)
      .then((result) => {
        const data = result.data;
        setBankProductList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankBranches = () => {
    callApi("GET", "bank_branch_get.php")
      .then((res) => res.data)
      .then((result) => {
        setBankBranchList(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserDetails = () => {
    callApi("POST", "bank_url_user_select.php", "")
      .then((res) => {
        if (res.status === 200) {
          setState((prevState) => ({
            ...prevState,
            UserListSet: res.data.responseJson,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageClickprevious = () => {
    setCurrentPage(currentPage - 1);
    console.log(currentPage);
  };
  const handlePageClicknext = () => {
    setCurrentPage(currentPage + 1);
    console.log(currentPage);
  };

  const handlepageListChange = (event: any) => {
    if (event.target.value == "All") {
      setSelectLimit(event.target.value);
      setItemsPerPage(state.UserListSet.length);
    } else {
      setSelectLimit(event.target.value);
      setItemsPerPage(event.target.value);
    }
  };
  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  const onClose = () => {
    setFormfields({
      id: "null",
      name: "",
      email: "",
      mob_no: "",
      password: "",
      confirm_password: "",
      selectedBankId: [""],
      selectedBranchId: [""],
      selectedProductId: [""],
    });
    setShow(false);
  };

  const saveUser = (title: any) => {
    console.log(
      "formFields_________",
      formFields,
      selectedBankId,
      selectedProductId
    );

    if (
      isEmpty(formFields.name) ||
      isEmpty(formFields.email) ||
      isEmpty(formFields.mob_no) ||
      isEmpty(formFields.password) ||
      isEmpty(formFields.confirm_password) ||
      formFields.selectedBankId.length === 0 ||
      formFields.selectedBranchId.length == 0 ||
      formFields.mob_no.length < 10
    ) {
      setIsvalid(true);
    } else if (
      Nameerror ||
      Emailerror ||
      Mobilenoerror ||
      passworderror ||
      confirmPassword ||
      PasswordMatch
    ) {
      if (Nameerror === true) {
        showError("Name Error", "Form not Valid!");
      } else if (Emailerror === true) {
        showError("Email Error", "Form not Valid!");
      } else if (Mobilenoerror === true) {
        showError("Mobile number Error", "Form not Valid!");
      } else if (passworderror === true) {
        showError("Password Error", "Form not Valid!");
      } else if (confirmPassword === true) {
        showError("Conformpassword Error", "Form not Valid!");
      } else {
        showError("Password does not match Error", "Form not Valid!");
      }
    } else {
      if (title === "Add User") {
        let request = {
          name: formFields.name,
          email: formFields.email,
          password: formFields.password,
          mob_no: formFields.mob_no,
          bank_id: formFields.selectedBankId,
          bank_branch_id: formFields.selectedBranchId,
          product_id: formFields.selectedProductId,
          selected_product_id: selectedProductId,
        };
        callApi("POST", "bank_url_user_create.php", request)
          .then((res: any) => res.data)
          .then((response: any) => {
            if (response.status === "200") {
              showSuccess("Success!", response.message);
              setFormfields({
                name: "",
                id: "",
                email: "",
                mob_no: "",
                password: "",
                confirm_password: "",
                selectedBankId: [""],
                selectedBranchId: [""],
                selectedProductId: [""],
              });
              setShow(false);
              getUserDetails();
            } else {
              showError("Failed", "Form Submission Failed!");
            }
          });
      } else {
        editingdetails();
      }
    }
  };

  const userEdit = (data: any) => {
    setFormfields({
      id: data.id,
      name: data.name,
      email: data.email,
      mob_no: data.phone_no,
      password: data.password,
      confirm_password: data.confirm_password,
      selectedBankId: data.bank_id,
      selectedBranchId: data.bank_branch_id,
      selectedProductId: data.product_id,
    });
    setSelectedBankId(data.bank_id);
    setSelectedProductId(data.selected_product_id);
    setSelectedBranchIds(data.bank_branch_id);
    filterProduct(data.bank_id);
    filterBranchesproduct(data.product_id);
    setModalTitle("Edit User");
    setShow(true);
  };

  const editingdetails = () => {
    let request = {
      user_id: formFields.id,
      name: formFields.name,
      email: formFields.email,
      password: formFields.password,
      mob_no: formFields.mob_no,
      bank_id: formFields.selectedBankId,
      bank_branch_id: formFields.selectedBranchId,
      product_id: formFields.selectedProductId,
      selected_product_id: selectedProductId,
    };
    callApi("post", "bank_url_user_edit.php", request)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response.status === "200") {
          showSuccess("EDITED!", response.message);
          getUserDetails();
          setFormfields({
            id: "",
            name: "",
            email: "",
            mob_no: "",
            password: "",
            confirm_password: "",
            selectedBankId: [""],
            selectedBranchId: [""],
            selectedProductId: [""],
          });
          setShow(false);
        } else {
          showError("FAILED!", "Editing Failed");
        }
      });
  };

  const userDelete = async (data: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to delete the user?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      let requ = {
        user_id: data.id,
      };
      callApi("POSt", "bank_url_user_delete.php", requ)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response.status === "200") {
            showSuccess("DELETED!", response.message);
            getUserDetails();
          } else {
            showError("FAILED!", "Delete failed");
          }
        });
    }
  };

  const AddUser = () => {
    setModalTitle("Add User");
    setShow(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormfields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === "mob_no") {
      const isValidInput = /^[0-9/-]*$/.test(e.target.value);
      if (!isValidInput || e.target.value.length < 10) {
        setMobilenoerror(true);
        console.error(
          "Invalid input. Please enter only numeric characters, slash, or hyphen."
        );
        return;
      } else {
        if (e.target.value.length <= 10) {
          setMobilenoerror(false);
          setFormfields((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
      }
    } else if (e.target.name === "email") {
      const ValidInput = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(
        e.target.value
      );
      const endsWithValidDomain =
        e.target.value.endsWith(".com") ||
        e.target.value.toLowerCase().endsWith(".in");
      if (!ValidInput && !endsWithValidDomain && e.target.value.length > 0) {
        setEmailerror(true);
        return;
      } else {
        if (e.target.value.length) {
          setEmailerror(false);
          setFormfields((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
      }
    } else if (e.target.name === "name") {
      const ValidINPUT = /^[a-zA-Z ]+$/.test(e.target.value);
      if (!ValidINPUT) {
        setNameerror(true);
        console.error("Invalid input. Please enter only characters.");
        return;
      } else {
        setNameerror(false);
        setFormfields((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      }
    } else if (e.target.name === "password") {
      const ValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(
        e.target.value
      );
      if (!ValidInput) {
        setpassworderror(true);
        console.error("Invalid input. Please enter a valid password.");
        return;
      } else {
        setpassworderror(false);
        setFormfields((prevState) => {
          const updatedState = {
            ...prevState,
            [e.target.name]: e.target.value,
          };
          if (updatedState.confirm_password !== updatedState.password) {
            setPasswordMatch(true);
          } else {
            setPasswordMatch(false);
          }
          return updatedState;
        });
      }
    } else if (e.target.name === "confirm_password") {
      // const ValidInput = /^(?=.[0-9])(?=.[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(e.target.value);
      const ValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(
        e.target.value
      );
      if (!ValidInput) {
        setConfirmpassword(true);
        console.error("Invalid input. Please enter a valid password.");
        return;
      } else {
        setConfirmpassword(false);
        setFormfields((prevState) => {
          const updatedState = {
            ...prevState,
            [e.target.name]: e.target.value,
          };
          if (updatedState.confirm_password !== updatedState.password) {
            setPasswordMatch(true);
          } else {
            setPasswordMatch(false);
          }
          return updatedState;
        });
      }
    }
  };
  const handleKeypress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[a-zA-Z ]+$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/;
    if (!regex.test(keyValue) && keyCode !== 8 && keyCode !== 46) {
      event.preventDefault();
    }
  };

  const downloadExcel = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${currentDate.getFullYear()}`;
    const flattenedData = state.UserListSet.map((user: any, index: any) => {
      return {
        "S.No": user.s_no,
        Name: user.name,
        "Email ID": user.email,
        "Phone Number": user.phone_no,
      };
    });
    const headers = ["S.No", "Name", "Email ID", "Phone Number"];
    const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
      header: headers,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Files");
    const fileName = `User List ${formattedDate}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const handleBankChange = (e: any) => {
    let selectedBankIds: any = [];
    e.forEach((item: any) => {
      selectedBankIds.push(item.id);
      console.log("item.id", item.id);
    });
    setFormfields((prevState) => ({
      ...prevState,
      selectedBankId: selectedBankIds,
    }));
    setSelectedBankId(selectedBankIds);
    filterProduct(selectedBankIds);
    console.log("e_______", e, selectedBankIds);
  };

  // const handleBranchChange = (e: any) => {
  //     console.log("setSelectedBranchIds__", selectedBranchIds);
  //     let BranchId: any = [];
  //     console.log("BranchId___", BranchId);
  //     e.forEach((item: any) => {
  //         BranchId.push(item.id);
  //         console.log("item.id", item.id);
  //     });
  //     console.log("BranchId___push___", BranchId);
  //     setSelectedBranchIds(BranchId);
  //     console.log("handleBranchChange____", e, BranchId);
  // };

  const handleBranchChange = (selectedOptions: any) => {
    const branchIds = selectedOptions.map((option: any) => option.id);
    const newIds = branchIds.filter(
      (id: any) => selectedBranchIds && !selectedBranchIds.includes(id)
    );
    const updatedIds =
      selectedBranchIds &&
      selectedBranchIds.filter((id: any) => branchIds.includes(id));
    setSelectedBranchIds(updatedIds ? [...updatedIds, ...newIds] : newIds);
    setFormfields((prevState) => ({
      ...prevState,
      selectedBranchId: updatedIds ? [...updatedIds, ...newIds] : newIds,
    }));
  };

  const filterProduct = (bankid: any) => {
    if (bankid) {
      const filteredProduct = bankProductList.filter((branch: any) =>
        bankid.includes(branch.bank_id)
      );
      console.log("filteredProduct____________________", filteredProduct);
      setFilterBankProductList(filteredProduct);
    } else {
      setselectedProduct("");
    }
  };

  // const handleProductChange = (e: any) => {
  //     console.log("e_______", e, formFields, selectedProductId);
  //     let selectedProductIds: any = [];
  //     e.forEach((item: any) => {
  //         selectedProductIds.push(item.id);
  //         console.log("item.id", item.id);
  //     });
  //     // setSelectedProductId(selectedProductIds);
  //     filterBranchesproduct(selectedProductIds);
  //     setFormfields(prevState => ({
  //         ...prevState,
  //         selectedProductId: selectedProductIds
  //     }));
  // }

  const handleProductChange = (e: any) => {
    console.log("e_______", e);
    const selectedIds = e.map((item: any) => item.value);
    const selectedProductIds = e.map((item: any) => item.product_id);
    setSelectedProductId(selectedIds);
    setSelectedIdProduct(selectedProductIds);
    filterBranchesproduct(selectedIds);
    setFormfields((prevState) => ({
      ...prevState,
      selectedProductId: selectedProductIds,
    }));
  };

  // const handleProductChange = (selectedOptions: any) => {
  //     console.log("selected option_____", selectedOptions);
  //     const selectedIds = selectedOptions.map((option: any) => option.id);
  //     const newIds = selectedIds.filter((id: any) => !selectedProductId.includes(id)); // Use !selectedProductId instead of selectedProductId
  //     const updatedIds = selectedProductId.filter((id: any) => selectedIds.includes(id)); // Remove selectedProductId && from this line
  //     // setSelectedProductId(updatedIds ? [...updatedIds, ...newIds] : newIds);
  //     setFormfields(prevState => ({
  //         ...prevState,
  //         selectedProductId: updatedIds ? [...updatedIds, ...newIds] : newIds
  //     }));
  // };

  const filterBranchesproduct = (productid: any) => {
    const filteredBranches = bankBranchList.filter((branch: any) => {
      return (
        productid.includes(branch.product_id) &&
        selectedBankId.includes(branch.bank_id)
      );
    });
    setFilterBranchList(filteredBranches);
    console.log("filterBranchesproduct_____", productid, filteredBranches);
  };

  return (
    <>
      <div className="mobile_nav_bar">
        <TopNavBar />
      </div>
      <div className="container mt-3">
        <div className="card">
          <div className="row">
            <div className="col-sm-6 col-md-6 ">
              <h6 className="heading">User Management</h6>
            </div>
            <div className="col-sm-6 col-md-6 add-user">
              <button onClick={() => AddUser()} className="add-user-btn d-flex">
                <BsFillPersonPlusFill
                  style={{ width: "30px", height: "25px", marginRight: "10px" }}
                />
                Add User
              </button>
            </div>
          </div>
        </div>
        <div className="card mt-5">
          <div className="row table-main-header">
            <div className="col-md-6 pl-3">
              <p className="branch-name">List of User</p>
            </div>
            <div className="col-md-6 pr-3">
              {/* <button className="download-btn" onClick={downloadExcel}>
                <img
                  className="download-image"
                  src={excelDownloadIcon}
                  alt=""
                />
                Download
              </button> */}
              <ExcelUser data={state.UserListSet} />
            </div>
          </div>
          <div className="table-container user-mobile-view pl-3 pr-3 mt-3">
            <table>
              <thead>
                <tr className="head-row">
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Email ID</td>
                  <td>Bank Name</td>
                  <td>Branch Name</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {currentItems?.length > 0 ? (
                  currentItems.map((file: any, index: any) => {
                    return (
                      <tr className="body-row" key={index}>
                        <td>{file.s_no}</td>
                        <td>{file.name}</td>
                        <td>{file.email}</td>
                        <td>
                          {Array.isArray(file?.bank_name) ? (
                            file.bank_name.length > 1 ? (
                              <>
                                {file.bank_name[0]}, {file.bank_name[1]}
                                {file.bank_name.length > 2 ? ", etc." : ""}
                              </>
                            ) : (
                              file.bank_name
                            )
                          ) : (
                            file.bank_name
                          )}
                        </td>
                        <td>
                          {Array.isArray(file?.branch_name) ? (
                            file.branch_name.length > 1 ? (
                              <>
                                {file.branch_name[0]}, {file.branch_name[1]}
                                {file.branch_name.length > 2 ? ", etc." : ""}
                              </>
                            ) : (
                              file.branch_name
                            )
                          ) : (
                            file.branch_name
                          )}
                        </td>

                        <td>
                          <div className="d-flex action-btn">
                            <button onClick={() => userEdit(file)}>
                              <img
                                src={editIcon}
                                alt="edit"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </button>
                            <button onClick={() => userDelete(file)}>
                              <img
                                src={deleteIcon}
                                alt="delete"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan={6}
                        style={{
                          textAlign: "center",
                          justifyItems: "center",
                          color: "gray",
                          fontSize: "20px",
                        }}
                      >
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <div className="pagination-and-info">
              <div className="pagination">
                <button
                  onClick={handlePageClickprevious}
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "5px",
                    padding: "0px 0px 5px 0px",
                    backgroundColor: "white",
                  }}
                  disabled={currentPage === 0}
                >
                  <img
                    src={pageleft}
                    alt="left arrow"
                    style={{ marginRight: "5px", width: "7px", height: "12px" }}
                  />
                </button>
                <span
                  style={{
                    fontSize: "18px",
                    padding: "0px 8px 8px 0px",
                    backgroundColor: "white",
                    margin: "5px 0px 0px 5px",
                    display: "flex",
                  }}
                >
                  {pageCount === 0 ? "0" : currentPage + 1}
                  <p style={{ padding: "0px 10px 0px 10px" }}>/</p>
                  {pageCount}
                </span>
                <button
                  onClick={handlePageClicknext}
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "5px",
                    padding: "0px 0px 5px 0px",
                    backgroundColor: "white",
                  }}
                  disabled={currentPage === pageCount - 1}
                >
                  <img
                    src={pageright}
                    alt="right arrow"
                    style={{ marginRight: "5px", width: "7px", height: "12px" }}
                  />
                </button>
              </div>
              <span className="mt-5" style={{ marginRight: "10px" }}>
                Total Count : {state.UserListSet.length}
              </span>
              <div className="row-per-page mt-5">
                <span style={{ marginRight: "10px" }}>Rows per page</span>
                <select
                  className="select"
                  aria-placeholder="Choose Branch"
                  value={selectLimit}
                  onChange={handlepageListChange}
                  style={{ height: "30px", width: "48px" }}
                >
                  {limitList.map((limitValue, index) => (
                    <option key={index} value={limitValue}>
                      {limitValue}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="user-form">
            <div className="row">
              <div className="col-md-12">
                <label>
                  Name<span style={{ color: "red", fontSize: "12px" }}>*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={formFields.name}
                  onChange={(e) => handleChange(e)}
                  onKeyPress={handleKeypress}
                  required
                  pattern="^[a-zA-Z]+$"
                />
                {isValid && isEmpty(formFields.name) ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                ) : (
                  ""
                )}
                {Nameerror && formFields.name.length > 0 && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Please enter only characters.
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <label>
                  Email Id
                  <span style={{ color: "red", fontSize: "12px" }}>*</span>
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email Id"
                  value={formFields.email}
                  onChange={(e) => handleChange(e)}
                  required
                />
                {isValid && isEmpty(formFields.email) && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                )}
                {Emailerror && formFields.email.length > 0 && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Please enter a valid email address '.com' (or) '.in'.
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <label>
                  Mobile Number
                  <span style={{ color: "red", fontSize: "12px" }}>*</span>
                </label>
                <input
                  type="tel"
                  id="mob_no"
                  name="mob_no"
                  placeholder="Enter your phone number"
                  value={formFields.mob_no}
                  onChange={(e) => handleChange(e)}
                  required
                  pattern="[0-9]*"
                  onKeyPress={handleKeyPress}
                  maxLength={10}
                />
                {isValid && isEmpty(formFields.mob_no) && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                )}
                {Mobilenoerror && formFields.mob_no.length < 10 && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Please enter a valid 10-digit phone number.
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <label>
                  Password
                  <span style={{ color: "red", fontSize: "12px" }}>*</span>
                </label>
                <div className="user-input-field d-flex mt-2">
                  <input
                    type={ShowpassWord ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formFields.password}
                    onChange={(e) => handleChange(e)}
                    required
                    minLength={8}
                  />
                  <img
                    src={ShowpassWord ? hidePassIcon : showPassIcon}
                    alt={ShowpassWord ? "Hide Password" : "Show Password"}
                    className={ShowpassWord ? "eye-onadd" : "eye-offadd"}
                    onClick={togglePasswordVisibility}
                  />
                </div>
                {isValid && isEmpty(formFields.password) && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                )}
                {passworderror && formFields.password.length > 0 && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required 8 characters with minimum of 1 uppercase,1
                      lowercase,1 special character and 1 numeric.
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <label>
                  Confirm Password
                  <span style={{ color: "red", fontSize: "12px" }}>*</span>
                </label>
                <div className="user-input-field d-flex mt-2">
                  <input
                    type={ShowPassWord ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formFields.confirm_password}
                    onChange={(e) => handleChange(e)}
                    minLength={8}
                    required
                  />
                  <img
                    src={ShowPassWord ? hidePassIcon : showPassIcon}
                    alt={ShowPassWord ? "Hide Password" : "Show Password"}
                    className={ShowPassWord ? "eye-onadd" : "eye-offadd"}
                    onClick={togglepasswordVisibility}
                  />
                </div>
                {isValid && isEmpty(formFields.confirm_password) && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                )}
                {confirmPassword && formFields.confirm_password.length > 0 && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required 8 characters with minimum of 1 uppercase,1
                      lowercase,1 special character and 1 numeric.
                    </p>
                  </>
                )}
                {PasswordMatch && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Password does not Match
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <label>
                  Choose Bank
                  <span style={{ color: "red", fontSize: "12px" }}>*</span>
                </label>
                <div className="select-field mt-2">
                  {bankList &&
                    bankList.length > 0 &&
                    bankList.map((opt: any) => {
                      opt.label = opt.name;
                      opt.value = opt.id;
                    }) && (
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={bankList}
                        name="bank_id"
                        value={bankList.filter(
                          (option: any) =>
                            selectedBankId &&
                            selectedBankId.includes(option.value)
                        )}
                        onChange={(e: any) => handleBankChange(e)}
                      />
                    )}
                </div>
                {isValid && formFields.selectedBankId.length === 0 && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <label>
                  Choose Product
                  <span style={{ color: "red", fontSize: "12px" }}>*</span>
                </label>
                <div className="select-field mt-2">
                  {bankProductList &&
                    bankProductList.map((opt: any) => {
                      opt.label = `${opt.product_name} - ${opt.bank_name}`;
                      opt.value = opt.id;
                    }) && (
                      // <Select closeMenuOnSelect={false} isMulti options={filterBankProductList} name="product_id"
                      //     value={bankProductList.find((o: any) => selectedProductId && selectedProductId.includes(o.value))}
                      //     onChange={(e) => handleProductChange(e)}
                      // />
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={filterBankProductList}
                        name="product_id"
                        value={bankProductList.filter((o: any) =>
                          selectedProductId.includes(o.value)
                        )}
                        onChange={(e) => handleProductChange(e)}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-12">
                <label>
                  Choose Branch
                  <span style={{ color: "red", fontSize: "12px" }}>*</span>
                </label>

                <div className="select-field mt-2">
                  {bankBranchList &&
                    bankBranchList.length > 0 &&
                    bankBranchList.map((opt: any) => {
                      opt.label = `${opt.name} - ${opt.bank_name}(${opt.product_name})`;
                      opt.value = opt.id;
                    }) && (
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={filterBranchList}
                        name="branch"
                        value={bankBranchList.filter(
                          (opt: any) =>
                            selectedBranchIds &&
                            selectedBranchIds.includes(opt.value)
                        )}
                        onChange={handleBranchChange}
                      />
                    )}
                </div>
                {isValid && formFields.selectedBranchId.length == 0 && (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => saveUser(modalTitle)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <SuccessModal
        show={showSuccessModal}
        onClose={handleCloseModal}
        title={modalTitle}
        body={modalBody}
      />
    </>
  );
};
export default AddUser;
