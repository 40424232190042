import React, { useState } from "react";
import ExcelJS from "exceljs";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import { callApi } from "../../../utils/Api";
import { Spinner } from "react-bootstrap";

const ExcelMisReport = ({ data }) => {
    const [loading, setLoading] = useState(false);

    const getCurrentFilesDetails = async () => {
        setLoading(true);
        try {
            const response = await callApi("POST", "bank_url_mis_report_new_excel.php", data);
            const fileData = response.data?.responseJson || [];
            exportExcelFile(fileData);
        } catch (error) {
            console.error("Error fetching file details:", error);
        } finally {
            setLoading(false);
        }
    };

    const exportExcelFile = async (data) => {
        if (!Array.isArray(data)) return console.error("Invalid data format:", data);

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("MIS Report");

        worksheet.columns = [
            { header: "S.No", key: "s_no", width: 10 },
            { header: "Bank Name", key: "organaization_name", width: 30 },
            { header: "Product", key: "product_name", width: 15 },
            { header: "Law Hands Branch Name", key: "region_name", width: 15 },
            { header: "Bank Branch Name", key: "branch_name", width: 15 },
            { header: "LAN No", key: "Lan_no", width: 15 },
            { header: "LH File No", key: "LH_fileNo", width: 15 },
            { header: "Customer Name", key: "customer_name", width: 15 },
            { header: "Property Owner Name", key: "property_owner_name", width: 25 },
            { header: "1st Set Received Document list", key: "first_set_doc_list", width: 30 },
            { header: "1st Set Document Submitted Date With Time Login", key: "first_doc_submit_date", width: 30 },
            { header: "1st Queries", key: "first_query", width: 30 },
            { header: "1st Query Raised Date & Time", key: "querie_raised_date", width: 30 },
            { header: "Final Query Raised Date & Time", key: "draft_preliminary_report", width: 30 },
            { header: "Current Pending Queries", key: "query", width: 30 },
            { header: "Final Query Document Submission by Bank Date With Time", key: "query_final", width: 30 },
            { header: "Final Report Released Date With Time", key: "Final_report_released_date", width: 30 },
            { header: "Final Status Queries Not Cleared / Final Report Pending / Report Released", key: "Final_status", width: 30 },
        ];

        worksheet.getRow(1).eachCell((cell) => {
            cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFFF00" } };
            cell.font = { bold: true, size: 14 };
        });

        data.forEach((file, index) => {
            worksheet.addRow({
                s_no: file.serial_no,
                organaization_name: file.organaization_name,
                product_name: file.product_name,
                region_name: file.region_name,
                branch_name: file.branch_name,
                Lan_no: file.Lan_no,
                LH_fileNo: file.LH_fileNo,
                customer_name: file.customer_name,
                property_owner_name: file.property_owner_name,
                first_set_doc_list: file.firstset_receivedDocList?.map((doc, i) => `${i + 1}. ${doc.mergedValue}`).join("\n") || "Nil",
                first_doc_submit_date: file.first_doc_submit_date || "Nil",
                first_query: file.first_query?.map((q) => q.mergedValue).join("\n") || "Nil",
                querie_raised_date: file.querie_raised_date || "Nil",
                draft_preliminary_report: file.draft_preliminary_report || "Nil",
                query: file.query_pending?.map((q) => q.mergedValue).join("\n") || "Nil",
                query_final: file.query_final || "Nil",
                Final_report_released_date: file.Final_report_released_date || "Nil",
                Final_status: file.Final_status || "Nil",
            }).eachCell((cell) => {
                cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
            });
        });

        const formattedDate = new Date().toLocaleDateString("en-GB").replaceAll("/", "-");
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `MIS_Report_${formattedDate}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <button className="download-btn" onClick={getCurrentFilesDetails} disabled={loading}>
            {loading ? <Spinner style={{ width: "20px", height: "20px" }} /> : (
                <>
                    <img className="download-image" src={excelDownloadIcon} alt="Download Excel" />
                    Download
                </>
            )}
        </button>
    );
};

export default ExcelMisReport;
