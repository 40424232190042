import React from "react";
import ExcelJS from "exceljs";
import excelDownloadIcon from "../../../../assets/dashboard/excel-download-icon.png";
import { useState } from "react";
import { callApi } from "../../../../utils/Api";
import { Spinner } from "react-bootstrap";

const ExcelDashboard = ({ data, path }) => {
  const [loading, setLoading] = useState(false);

  const getCurrentFilesDetails = async () => {
    try {
      setLoading(true);
      const response = await callApi(
        "POST",
        "bank_url_sub_report_new_excel.php",
        data
      );
      setLoading(false);
      if (response?.data?.data) {
        exportExcelFile(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const exportExcelFile = async (data) => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define columns with headers
    worksheet.columns = [
      { header: "S.No", key: "s_no", width: 10 },
      { header: "Name", key: "borrower_name", width: 30 },
      { header: "Application No", key: "app_id", width: 20 },
      { header: "LH File No", key: "file_name", width: 15 },
    ];

    // Apply alignment and color to header cells
    worksheet.getRow(1).eachCell((cell) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow color
      };
      cell.font = { bold: true, size: 14 }; // Bold and font size 20
    });

    const flattenedData = data.map((file, index) => {
      return {
        s_no: file.s_no,
        borrower_name: Array.isArray(file.borrower_name)
          ? file.borrower_name.join(", ")
          : file.borrower_name,
        app_id: file.app_id,
        file_name: file.file_name,
      };
    });

    // Add data rows with custom cell alignment and wrap text
    flattenedData.forEach((item) => {
      const row = worksheet.addRow(item);
      row.eachCell((cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getFullYear()}`;
    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${path} ${formattedDate}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <button className="download-btn" onClick={getCurrentFilesDetails} disabled={loading}>
        {/* <img
          className="download-image"
          // src={excelDownloadIcon}
          // alt="Download Excel"
        /> */}
        {loading ? <Spinner style={{ width: "20px", height: "20px" }} /> : <img className="download-image" src={excelDownloadIcon} alt="Download Excel" />}Download
      </button>
    </>
  );
};

export default ExcelDashboard;
